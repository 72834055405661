<template>
  <span :class="dividerClass" class="divider my-2" />
</template>

<script>
export default {
  name: 'DividerComponent',

  props: {
    type: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical'].includes(value)
    }
  },

  computed: {
    dividerClass() {
      return {
        'divider-vertical': this.type === 'vertical',
        'divider-horizontal': this.type === 'horizontal'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/components';

.divider {
  display: block;

  &-horizontal {
    border: 1px solid $dark-grey-color-light;
  }

  &-vertical {
    border-right: 1px solid $dark-grey-color-light;
  }
}
</style>
