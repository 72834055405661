<template>
  <div
    class="products-formats-selection"
    :class="{
      'products-formats-selection--gt3': formats.length > 3,
      'products-formats-selection--unavailable': !isAvailableInRegion
    }"
  >
    <div v-if="isGarmentsProduct" class="price-container">
      <div class="price-container__amount">
        {{ priceAmount }}
      </div>
      <div v-if="isTaxLabelVisible" class="price-container__tax">
        &nbsp;+ {{ $t('tax') }}
      </div>
    </div>

    <div v-if="isGarmentsProduct" class="garment mb-2">Select size</div>

    <div class="products-formats-selection__list">
      <div
        v-if="fillingColumnSize"
        class="products-formats-selection__list__filling-column"
        :class="{ 'span-2': fillingColumnSize === 2 }"
      >
        <RegionFlag />
        <template v-if="isAvailableInRegion">
          Formats available in your location:
        </template>
        <template v-else>
          Unfortunately this title is unavailable in your location
        </template>
      </div>

      <div v-for="format in formats" :key="format.name">
        <b-btn
          variant="outline-primary"
          class="d-flex flex-column justify-content-around h-100 w-100"
          :class="{ active: value === format._id }"
          @click="onChange(format._id)"
        >
          <div
            class="products-formats-selection__list__format-name d-flex align-items-center justify-content-center"
          >
            {{ getProductFormatName(format) }}
          </div>
          <div class="products-formats-selection__list__format-price">
            <div v-if="!isGarmentsProduct">
              <span class="products-formats-selection__list__price">
                {{ getProductFormatPriceAmount(format) }}
              </span>
              <span
                v-if="
                  getProductFormatPrice(format).taxBehaviour === 'exclusive'
                "
                class="products-formats-selection__list__tax"
              >
                +&nbsp;{{ $t('tax') }}
              </span>
            </div>
          </div>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getFormatPrice from '@/misc/getFormatPrice';
import formatMoney from '@/misc/formatMoney';
import { ProductFormatAvailability } from '@/misc/constants';
import getProductFormatName from '@/misc/getProductFormatName';
import RegionFlag from '@/components/RegionFlag/index.vue';

export default {
  name: 'ProductFormatsButton',
  components: { RegionFlag },

  props: {
    formats: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },

  data() {
    return { selectedFormatId: null };
  },

  computed: {
    ...mapGetters({
      region: 'config/region',
      currency: 'config/currency'
    }),
    format() {
      return this.findFormat(this.selectedFormatId);
    },
    price() {
      return this.getProductFormatPrice(this.format);
    },
    priceAmount() {
      return this.getProductFormatPriceAmount(
        this.findFormat(this.selectedFormatId)
      );
    },
    isGarmentsProduct() {
      return this.calculateGarmentsProduct();
    },
    isTaxLabelVisible() {
      return this.price.taxBehaviour === 'exclusive';
    },
    fillingColumnSize() {
      return Math.max(0, 3 - this.formats.length);
    },
    isAvailableInRegion() {
      return this.formats.length > 0;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedFormatId = this.value ?? this.selectedFormatId;
      }
    }
  },

  methods: {
    getProductFormatName,
    calculateGarmentsProduct() {
      return (
        this.formats.filter(
          ({ availability }) =>
            availability === ProductFormatAvailability.Available
        ).length > 4
      );
    },
    findFormat(id) {
      return this.formats.find((format) => format._id === id);
    },
    getProductFormatPrice(item) {
      return getFormatPrice(item, this.region);
    },
    getProductFormatPriceAmount(item) {
      const price = this.getProductFormatPrice(item);
      return formatMoney(price?.amount, this.currency);
    },
    onChange(formatId) {
      this.selectedFormatId = formatId;
      this.$emit('change', formatId);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/utilites';
@import '../../styles/components';

.price-container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  line-height: 1;

  &__amount {
    font-size: $font-size-extra-large;
    font-weight: $font-weight-bold;
  }

  &__tax {
    font-size: $font-size-small;
  }
}

.garment,
.price-container {
  color: $black-color;

  @media (min-width: $screen-width-md) {
    color: $white-color;
  }
}

.products-formats-selection__list {
  display: flex;
  align-items: center;
  label {
    span {
      height: 100%;
    }
  }

  .products-formats-selection__list__tax {
    white-space: nowrap;
    font-size: $font-size-small;
    letter-spacing: 0;
  }

  .btn {
    min-height: 50px;
    padding: 2px 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;
    font-size: $font-size-semi-large;
    border-radius: $button-border-radius-md;
    border: unset;
    flex: 1;

    &.btn-outline-primary {
      color: $black-color;
      background-color: white;

      &.active {
        color: $black-color;
        background-color: $primary-color;
      }
    }
  }
}
.isSameWidth {
  .btn {
    width: 1%;
  }
}
</style>

<style lang="scss">
.products-formats-selection__list {
  gap: 3%;
  > div {
    min-width: 31.2%;
    flex-grow: 1;
    text-align: center;
    .btn {
      box-shadow: none !important;

      @media (max-width: $screen-width-lg) {
        max-height: 48px !important;
        min-height: 48px !important;
      }
    }

    &.products-formats-selection__list__filling-column {
      text-align: center;
      text-wrap: balance;
      line-height: 1.2;
      color: $white-color;
      font-size: 11px;

      @media (min-width: $screen-width-lg) {
        color: $white-color;
      }

      @at-root .product__purchase--sticky & {
        color: $black-color;
        @media (min-width: $screen-width-lg) {
          color: $white-color;
        }
      }

      @at-root .products-formats-selection--unavailable &,
        &.span-2 {
        min-width: 31.2% * 2;
        img {
          margin-right: 2px;
          display: inline-block;
        }
      }

      img {
        margin: 0 auto 5px;
        display: block;
      }
    }
  }

  @at-root .products-formats-selection--gt3 & > div {
    @media (min-width: $screen-width-lg) {
      min-width: initial;
    }
  }

  &__format-name {
    line-height: 1;
    font-size: $font-size-smaller;
    font-family: 'Poppins-SemiBold', sans-serif;

    @media (min-width: $screen-width-lg) {
      font-size: $font-size-semi-large;
    }
  }

  &__format-price {
    line-height: 1;
    font-size: $font-size-normal;

    @media (min-width: $screen-width-lg) {
      font-size: $font-size-large;
    }
  }
}
</style>
