import dayjs from 'dayjs';

export default function getReleaseDateMessage(dateStr) {
  const shouldAddYear = !dayjs(dateStr).isSame(new Date(), 'year');
  const date = dayjs(dateStr)
    .utc()
    .format(`D MMMM${shouldAddYear ? ' YYYY' : ''}`);

  return `<b>Pre-order now</b>: Releasing ${date}`;
}
