<template>
  <p v-if="isVisible" class="FeeDisclaimer">
    You may incur customs fees or import duties as orders are shipped from
    outside your country.
    <br />
    <a href="/terms" target="_blank"> Full terms and conditions</a>.
  </p>
</template>

<script>
import { mapGetters } from 'vuex';

const FEE_REGIONS = ['CA', 'EU', 'LATAM'];

export default {
  name: 'FeeDisclaimer',
  computed: {
    ...mapGetters('config', ['regionName', 'region']),
    isVisible() {
      return FEE_REGIONS.includes(this.region);
    }
  }
};
</script>

<style lang="scss" scoped>
.FeeDisclaimer {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
</style>
