<template>
  <div class="creator__links">
    <template v-for="platform in getPlatforms()">
      <img
        v-if="getImageItem(platform.link)"
        :key="platform.platformId"
        class="ml-3 cursor-pointer"
        :src="getImageItem(platform.link)"
        :alt="platform.link"
        @click.stop="handleSocialIconClick(platform.link)"
      />
    </template>
  </div>
</template>

<script>
import { includes } from 'lodash';
import { SupportedCreatorPlatforms } from '@/misc/constants';

export default {
  name: 'CreatorLinks',

  props: {
    sourceType: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    getImageItem(link) {
      if (link) {
        const domain = new URL(link);
        const key = domain.host.replace('.com', '').replace('www.', '');
        if (includes(SupportedCreatorPlatforms, key)) {
          return require(`@/assets/icons/creatorSocial/${key}.svg`);
        }
      }
    },
    handleSocialIconClick(link) {
      window.open(link, '_blank');
    },
    getPlatforms() {
      switch (this.sourceType) {
        case 'creator':
          return this.$get(this.data, 'platforms', []);
        case 'product':
          return this.$get(this.data, 'creator.platforms', []);
        default:
          return this.$get(this.data, 'platforms', []);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
