import { ProductFormatAvailability } from './constants';
import { storeInstance } from '@/lib/storeInstance';

export default function getDefaultProductFormat(product) {
  const region = storeInstance.getters['config/region'];

  const formats =
    product?.formats?.filter(
      (format) =>
        format.availability === ProductFormatAvailability.Available &&
        format.prices?.find((price) => price?.region === region)
    ) || [];
  return formats[0] || {};
}
