<template>
  <div class="view-more">
    <span
      ref="viewMoreText"
      class="view-more__text"
      :style="{ maxHeight: textMaxHeight }"
      >{{ text }}</span
    >
    <div
      v-show="showToggle"
      class="view-more__toggle"
      @click.stop="toggleShowContent"
    >
      <span class="view-more__toggle-text">
        {{ showContent ? 'Read less' : 'Read more' }}
      </span>
      <img
        class="view-more__icon"
        :class="{ 'view-more__icon-active': showContent }"
        src="@/assets/icons/view-more.svg"
        alt="arrow-icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewMore',

  props: {
    text: {
      type: String,
      default: ''
    },
    lines: {
      type: Number,
      default: 0
    },
    lineHeight: {
      type: Number,
      default: 27
    }
  },

  data() {
    return { showContent: false, showToggle: false };
  },

  computed: {
    textMaxHeight() {
      return !this.lines || this.showContent || !this.showToggle
        ? 'none'
        : `${this.maxTextHeightNumber}px`;
    },
    maxTextHeightNumber() {
      return this.lineHeight * this.lines;
    }
  },

  mounted() {
    const textFitsToBlock = this.maxTextHeightNumber >= this.getTextHeight();
    if (!textFitsToBlock) {
      this.showToggle = true;
    }
  },

  methods: {
    toggleShowContent() {
      this.showContent = !this.showContent;
    },
    getTextHeight() {
      return this.$refs.viewMoreText?.clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.view-more {
  display: flex;
  flex-direction: column;

  &__text {
    overflow: hidden;
    white-space: pre-line;
  }

  &__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $primary-color;
    margin-top: 10px;
    height: 26px;
    transition: 0.8s;

    &-text {
      margin-right: 15px;
    }
  }

  &__icon {
    transition: 0.3s;

    &-active {
      transform: rotate(180deg);
    }
  }
}
</style>
