<template>
  <nuxt-img
    v-bind="$attrs"
    :id="id"
    :attr="$attrs"
    :src="url"
    :alt="alt || id"
    :fit="fit"
    :format="format"
    :provider="imageLoadingError ? null : 'cloudinary'"
    @error="loadImage"
    @click="$emit('click', $event)"
  />
</template>

<script>
export default {
  name: 'ImageWrapper',

  props: {
    id: {
      type: [String, Number],
      required: true
    },
    alt: {
      type: String,
      default: null
    },
    url: {
      type: String,
      required: true
    },
    fit: {
      type: String,
      default: 'fill'
    },
    format: {
      type: String,
      default: 'webp'
    }
  },

  data() {
    return {
      imageLoadingError: false
    };
  },

  methods: {
    loadImage() {
      this.imageLoadingError = true;
    }
  }
};
</script>
