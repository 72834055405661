<template>
  <div class="product-images" :class="{ 'product-images--modal': isModalMode }">
    <div
      v-if="isModalMode"
      class="product-images__close-modal"
      @click="isModalMode = false"
    >
      <img src="@/assets/icons/close-dark.svg" />
    </div>
    <div class="product-images__inner">
      <div class="product-images__main" @click.prevent.stop>
        <div ref="images" class="swiper relative">
          <div class="swiper-wrapper">
            <template v-if="images && images.length">
              <div
                v-for="(image, index) in images"
                :key="image.url"
                class="swiper-slide product-images__main__image"
              >
                <div @click="handleSlideClick($event, 'image')">
                  <CloudinaryImage
                    :id="`product-image-${index}`"
                    :alt="`product-main-image-${index}`"
                    :url="image.url"
                    :width="445"
                    :height="445"
                  />
                </div>
              </div>
              <div
                v-if="videoFile"
                class="swiper-slide product-images__main__video"
                :class="{
                  'product-images__main__video--pause': !isVideoPlaying
                }"
              >
                <div @click="handleSlideClick($event, 'video')">
                  <video
                    ref="video"
                    :controls="isVideoPlaying"
                    @play="() => (isVideoPlaying = true)"
                    @pause="() => (isVideoPlaying = false)"
                    @ended="() => (isVideoPlaying = false)"
                  >
                    <source :src="`${videoFile.urlData.url}#t=0.0001`" />
                  </video>
                  <img
                    v-if="!isVideoPlaying"
                    src="@/assets/icons/video-play-overlay.svg"
                    alt="Play Video"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <img
                class="swiper-slide"
                alt="product-main-image"
                :src="FallbackImageURL"
                width="445"
                height="445"
              />
            </template>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </div>
        <product-badge v-if="isPreOrder" type="pre-order" />
      </div>

      <div
        v-if="(images && images.length > 1) || videoFile"
        ref="thumbs"
        class="product-images__thumbs swiper mt-2"
      >
        <div class="swiper-wrapper">
          <div
            v-for="(image, index) in images"
            :key="image.url"
            class="swiper-slide"
            @click="handlePaginationClick(index)"
          >
            <span />
          </div>

          <div
            v-if="videoFile"
            class="swiper-slide product-images__thumbs--video"
          >
            <img src="@/assets/icons/video-play.svg" alt="Play Video" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination, Swiper, Navigation, Thumbs } from 'swiper';
import CloudinaryImage from '@/components/Elements/Image';
import ProductBadge from '@/components/ProductBadge';
import { FallbackImageURL } from '@/misc/constants';
import 'swiper/swiper-bundle.min.css';

export default {
  name: 'ProductImages',

  components: {
    CloudinaryImage,
    ProductBadge
  },

  props: {
    images: {
      type: Array,
      default: () => []
    },
    isPreOrder: {
      type: Boolean,
      default: false
    },
    isDigitalFormatOnly: {
      type: Boolean,
      default: false
    },
    videoFile: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      FallbackImageURL,
      swiper: null,
      swiperThumbs: null,
      selectedSlideIndex: 0,
      isVideoPlaying: false,
      isModalMode: false
    };
  },

  watch: {
    isModalMode() {
      if (!this.isModalMode) {
        this.$refs.video?.pause?.();
      }
    }
  },

  mounted() {
    const isOnlyOneImage =
      !!(this.images && this.images.length <= 1) && !this.videoFile;
    const thumbsSwiper = isOnlyOneImage
      ? null
      : new Swiper(this.$refs.thumbs, {
          slidesPerView: 6,
          allowTouchMove: false
        });
    this.swiper = new Swiper(this.$refs.images, {
      modules: [Navigation, Thumbs, Pagination],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      thumbs: {
        swiper: thumbsSwiper,
        slideThumbActiveClass: 'product-images__thumbs__selected'
      },
      on: {
        slideChange: (swiper) => {
          if (!this.$refs.video) return;

          const slides = swiper.slides;
          const videoIndex = slides.length - 1;
          this.selectedSlideIndex = swiper.activeIndex;

          if (videoIndex === swiper.activeIndex) {
            // this.$refs.video.play();
          } else {
            this.$refs.video.pause();
          }
        }
      }
    });
  },
  methods: {
    handlePaginationClick(index) {
      this.selectedSlideIndex = index;
    },
    handleSlideClick($event, slideType) {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;

      if (slideType === 'video') {
        if (isMobile) {
          if (!this.isVideoPlaying && this.isModalMode) {
            $event.preventDefault();
            $event.stopPropagation();

            this.$refs.video.play();
          }

          this.isModalMode = true;
        }

        if (!isMobile && !this.isVideoPlaying) {
          this.$refs.video.play();
        }
      }

      if (slideType === 'image' && isMobile) {
        this.isModalMode = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$imageMaxWidth: 60%;

.product-images {
  background: $dark-color;
  transition: all 0.5s;

  &__main {
    position: relative;
    @media (max-width: $screen-width-md) {
      margin: 0 -15px;
    }

    // Generic slide properties
    .swiper-slide {
      position: relative;
      height: auto;

      @media (min-width: $screen-width-md) {
        cursor: pointer;
      }

      &:after {
        content: '';
        padding-bottom: 100%;
        display: none;
        @media (min-width: $screen-width-md) {
          display: block;
        }
      }

      > div {
        position: relative;
        @media (min-width: $screen-width-md) {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }

    // Slide with image
    &__image {
      img {
        width: 100%;
        max-width: $imageMaxWidth;
        height: auto;
        margin: 0 auto;
        display: block;

        @media (min-width: $screen-width-md) {
          max-width: none;
        }
      }
    }

    // Slide with video
    &__video {
      position: relative;
      display: flex;
      align-items: center;

      video {
        width: 100%;
        height: 100%;
      }

      // play video overlay
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 20;
      }

      // backdrop
      &.product-images__main__video--pause {
        > div:before {
          content: '';
          background: rgba(black, 0.6);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
          pointer-events: none;
        }
      }
    }

    .swiper-pagination {
      bottom: -20px;
    }
  }

  &__digital-badge {
    position: absolute;
    width: 80px;
    right: 30px;
    top: 0;

    @media (max-width: $screen-width-lg) {
      right: 20px;
      width: 60px;
    }

    @media (max-width: $screen-width-md) {
      right: 30px;
      width: 80px;
    }

    @media (max-width: $screen-width-xm) {
      right: 20px;
      width: 60px;
    }
  }

  &__pre-order {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__thumbs {
    .swiper-wrapper {
      justify-content: center;
      align-items: center;
    }

    .swiper-slide {
      cursor: pointer;
      width: auto !important;
      min-width: 24px;

      span {
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(white, 0.4);
        border-radius: 50%;
        margin: 0 auto;

        @at-root .product-images--modal & {
          background: rgba(black, 0.4);
        }
      }
    }

    &--video {
      margin-left: 8px;

      img {
        margin: 0 auto;
      }
    }

    &__selected.swiper-slide {
      span {
        background: white;

        @at-root .product-images--modal & {
          background: black;
        }
      }
    }
  }

  .swiper-button {
    &-next,
    &-prev {
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $dark-grey-color;
      width: 50px;
      height: 50px;
      opacity: 0.7;

      &:after {
        content: none;
      }

      @media (max-width: $screen-width-md) {
        display: none;
      }
    }

    &-next {
      background-image: url('assets/icons/arrows/right.svg');
    }

    &-prev {
      background-image: url('assets/icons/arrows/left.svg');
    }
  }

  .swiper-button-disabled {
    opacity: 0.3;
  }

  &--modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $light-grey-color;
    z-index: 20;

    .product-images__inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .product-images__main {
      padding: 0 8px;

      @media (max-width: $screen-width-md) {
        margin: 0;
      }

      &__image {
        img {
          max-width: none;
          @media (min-width: $screen-width-md) {
            max-width: 800px;
          }
        }
      }
    }
  }

  &__close-modal {
    text-align: right;
    padding: 5px 20px;
    cursor: pointer;
  }
}
</style>
