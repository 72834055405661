<template>
  <div
    class="ProductBadge"
    :class="{ 'ProductBadge--pre-order': type === 'pre-order' }"
  >
    Pre-Order now!
  </div>
</template>

<script>
export default {
  name: 'ProductBadge',
  props: {
    type: {
      type: String,
      enum: ['digital', 'pre-order'],
      default: null
    }
  }
};
</script>

<style lang="scss">
.ProductBadge {
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 10;
  text-transform: uppercase;
  font-size: $font-size-small;
  padding: 2px 6px;
  &--pre-order {
    background: $teal-color;
  }

  @media (min-width: $screen-width-md) {
    top: 16px;
    right: -10px;
    font-size: $font-size-extra-large;
    padding: 4px 12px;
  }
}
</style>
