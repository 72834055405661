<template>
  <img
    v-if="selectedRegion"
    :src="selectedRegion.icon"
    :alt="selectedRegion.isoCountry"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { countryIcons } from '@/components/RegionDropdown/constants';
import { countryNames } from '@/misc/constants';

export default {
  name: 'RegionFlag',
  props: {
    region: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('config', {
      regions: 'regions',
      storeRegion: 'region'
    }),
    regionsWithFlag() {
      return this.regions.map(({ isoCountry, isoCurrency, ...rest }) => ({
        isoCurrency,
        isoCountry,
        icon: countryIcons[isoCountry],
        country: countryNames[isoCountry],
        ...rest
      }));
    },
    selectedRegion() {
      return this.regionsWithFlag.find(
        ({ isoCountry }) => isoCountry === (this.region ?? this.storeRegion)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  box-shadow: 0 0 0 0.5px #fff;
}
</style>
