<template>
  <div
    class="product__purchase"
    :class="{ 'product__purchase--sticky': allowStickyMode }"
  >
    <h2
      v-if="!isProductPage"
      class="mb-0 mt-md-0 mt-4 cursor-pointer"
      @click="handleProductClick"
    >
      {{
        isRecipePage
          ? 'This recipe is featured in ' + product.name
          : product.name
      }}
    </h2>
    <div v-if="productDescription" class="product__purchase-text mt-2 mb-4">
      <ViewMore :text="productDescription" :lines="4" :line-height="22" />
    </div>

    <Divider class="mt-5 mb-4" />

    <Transition name="bottom-banner-slide">
      <div class="product__formats pt-2 pb-1 pt-lg-0 pb-lg-0">
        <div
          v-if="!isAvailableInRegion"
          class="product__availability mb-2 mb-lg-4"
        >
          <div class="product__availability__message text-center">
            <b>Unavailable</b>
          </div>
        </div>

        <div v-else-if="isPreOrder" class="product__availability mb-2 mb-lg-4">
          <div
            class="product__availability__message text-center"
            v-html="releaseDateMessage"
          />
        </div>

        <div v-else class="product__availability mb-2 mb-lg-4">
          <div class="product__availability__message text-center">
            <b>Available:</b> Add to basket
          </div>
        </div>

        <div class="product__purchase px-4 px-lg-0">
          <ProductFormatsButton
            :formats="productFormats"
            :value="selectedProductFormatId || defaultProductFormatId"
            class="personalisation-panel__purchase-cover-radio mb-3"
            @change="onFormatChange"
          />
        </div>

        <template v-if="isAvailableInRegion">
          <Button
            class="product__add-to-basket-btn mb-3 px-4 px-lg-0"
            full-width
            :loading="addToBasketLoading"
            variant="primary"
            @click="handleAddToBasketClick"
          >
            Add to basket
          </Button>

          <div class="product__fee-disclaimer px-4 px-lg-0">
            <FeeDisclaimer />
          </div>

          <div class="product__payment-icons">
            <payment-icons variant="productPage" />
          </div>
        </template>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  CarouselModes,
  ProductAvailability,
  ProductFormatAvailability
} from '@/misc/constants';

import Divider from '@/components/Elements/Divider';
import Button from '@/components/Elements/Button';
import ViewMore from '@/components/ViewMore';
import ProductFormatsButton from '@/components/ProductFormatsButton';
import FeeDisclaimer from '@/components/FeeDisclaimer.vue';

import getReleaseDateMessage from '@/misc/getReleaseDateMessage';
import getDefaultProductFormat from '@/misc/getDefaultProductFormat';
import PaymentIcons from '@/components/PaymentIcons/PaymentIcons.vue';

export default {
  name: 'PurchaseProduct',

  components: {
    PaymentIcons,
    Button,
    Divider,
    ViewMore,
    ProductFormatsButton,
    FeeDisclaimer
  },

  props: {
    product: {
      type: Object,
      default: () => {}
    },
    questions: {
      type: Array,
      default: () => []
    },
    isRecipePage: {
      type: Boolean,
      default: false
    },
    isProductPage: {
      type: Boolean,
      default: false
    },
    allowStickyMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      buyNowLoading: false,
      addToBasketLoading: false,
      CarouselModes,
      quantity: 1,
      ProductAvailability,
      getReleaseDateMessage,
      selectedProductFormatId: null
    };
  },

  computed: {
    ...mapGetters({
      redirectUrl: 'checkout/redirectUrl',
      currency: 'config/currency',
      region: 'config/region'
    }),
    ...mapState('config', ['config']),
    productDescription() {
      return this.product?.fullDescription || this.product?.shortDescription;
    },
    isPreOrder() {
      return this.product.availability === ProductAvailability.PreOrder;
    },
    releaseDateMessage() {
      return getReleaseDateMessage(this.product.expectedReleaseDate);
    },
    productFormats() {
      return (this.product?.formats || []).filter(
        ({ availability, prices }) =>
          availability === ProductFormatAvailability.Available &&
          prices.some((price) => price.region === this.region)
      );
    },
    isAvailableInRegion() {
      return this.productFormats.length > 0;
    },
    defaultProductFormatId() {
      return this.$get(getDefaultProductFormat(this.product), '_id', '');
    }
  },

  methods: {
    ...mapActions({
      createSession: 'checkout/createSession'
    }),
    ...mapMutations({
      ADD_ITEM: 'checkout/ADD_ITEM'
    }),
    handleAddToBasketClick() {
      this.addToBasketLoading = true;
      this.ADD_ITEM({
        product: this.product,
        count: this.quantity,
        productFormatId:
          this.selectedProductFormatId || this.defaultProductFormatId
      });
      setTimeout(() => {
        this.addToBasketLoading = false;
        this.$router.push('/basket');
      }, 650);
    },
    handleProductClick() {
      this.$router.push({
        path: `/products/${this.$get(this.product, '_id')}`
      });
    },
    onFormatChange(formatId) {
      this.selectedProductFormatId = formatId;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../styles/utilites';
@import '../../styles/components';

.product {
  overflow-x: hidden;

  &__purchase {
    overflow: scroll;

    // hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &-text {
      line-height: 22px;
    }

    &__availability {
      color: $white-color;

      &__message {
        line-height: 22px;
      }
    }

    &.product__purchase--sticky {
      .product__formats {
        position: fixed;
        display: flex;
        flex-direction: column;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: $light-grey-color;
        box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.16);

        @media (min-width: $screen-width-lg) {
          position: relative;
          background: none;
          box-shadow: none;
        }
      }

      .product__availability {
        color: $black-color;

        @media (min-width: $screen-width-lg) {
          color: $white-color;
        }
      }
    }
  }

  &__fee-disclaimer {
    color: $black-color;
    @media (min-width: $screen-width-lg) {
      color: $white-color;
    }
  }

  &__payment-icons {
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.product {
  &__purchase {
    &.product__purchase--sticky {
      .product__add-to-basket-btn {
        .btn {
          @media (max-width: $screen-width-lg) {
            height: 48px !important;
            padding: 10px 30px;
          }
        }
      }
    }
  }

  &__availability {
    &__message {
      b {
        font-family: 'Poppins-SemiBold', sans-serif;
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
