import filter from 'lodash/filter';
import { ProductFormatAvailability } from '@/misc/constants';

const filterProductByRegion = (products, region) => {
  return filter(products, (product) => {
    product.formats = product?.formats?.filter(
      (format) =>
        format.availability === ProductFormatAvailability.Available &&
        format.prices?.find((price) => price?.region === region)
    );

    return product.formats.length;
  });
};

export default filterProductByRegion;
